<template>
  <div class="loadTableList">
    <h3>sql文件导入</h3>
    <lh-button accept=".sql" multiple @change="addFile" width="100%">选择文件</lh-button>
    <!-- <table>
        <tr>
            <td>url:</td>
            <td><lh-input placeholder="请输入数据库地址" v-model="dataBase.url"/></td>
        </tr>
        <tr>
            <td>user:</td>
            <td><lh-input placeholder="请输入用户名" v-model="dataBase.user"/></td>
        </tr>
        <tr>
            <td>password:</td>
            <td><lh-input placeholder="请输入密码" v-model="dataBase.password"/></td>
        </tr>
    </table> -->
    <div class="fileList" v-if="fileList.length>0">
        <div class="item" v-for="(file,index) of fileList" :key="file.name">
            <span>{{ file.name }}</span>
            <lh-button type="delete" @click="deleteFile(index)">删除</lh-button>
        </div>
    </div>
    <lh-button :disabled="fileList.length==0" @click="load()" width="100%">导入</lh-button>
    
    <!-- <div v-if="tableList">
        <h3>请选择导入的表</h3>
        <div><lh-button @click="loadTableList()">导入</lh-button></div>
        <lh-input placeholder="查找-请输入表名"/>
        <div v-for="(tableItem,index) of tableList" :key="tableItem">
            <lh-checkbox v-model="selectList[index]"/>{{tableItem}}
        </div>
    </div> -->
  </div>
</template>

<script>
//import request from "../../../utils/request"
//import qs from 'qs'
//import mysql from "mysql"
//import connect from "../../../server/index"
import Table from '../class/Table'
import Field from '../class/Field'
export default {
    name:"LoadTableList",
    data(){
        return {
            dataBase:{
                url:"",
                user:"",
                password:""
            },
            //tableList:null,
            fileList:[],
            selectList:[]
        }
    },
    mounted(){
        let temp=JSON.parse(localStorage.getItem("dataBase"));
        if(temp!=null){
            this.dataBase=temp;
        }
    },
    methods:{
        handleFileText:function(text){
            let fileline = text.trim().split(/[,/\n\r/]/);
            //console.log(fileline);
            let fieldList=[];
            let tableName="";
            let primarykey=null;
            for(let i=0;i<fileline.length;i++){
                let line=fileline[i].trim();
                if(line==""){
                    continue;
                }
                let index=line.indexOf("CREATE TABLE");
                if(index!=-1){
                    let items=line.split("`");
                    tableName=items[1];
                    items=fileline[i].split("(");
                    if(items[1].trim()!=""){
                        fieldList.push(items[1].trim());
                    }
                    for(let j=i+1;j<fileline.length;j++){
                        line=fileline[j].trim();
                        if(line.indexOf('`')==0){
                            //console.log(line);
                            fieldList.push(line);
                        }else{
                            if(line.indexOf("PRIMARY KEY")!=-1){
                                items=line.split("`");
                                primarykey=items[1];
                            }
                        }
                    }
                    let table=this.handleTableText(tableName,fieldList,primarykey);
                    return table;
                }
            }
        },
        handleTableText:function(tableName,fieldTextList,primarykey){
            //console.log("handleTableText...");
            //console.log(fieldTextList);
            let table=new Table();
            let fieldList=[];
            table.name=tableName;
            for(let i=0;i<fieldTextList.length;i++){
                let items=fieldTextList[i].split("`");
                let field=new Field();
                field.name=items[1];
                if(field.name==primarykey){
                    field.isPrimaryKey=true;
                    field.isUnique=true;
                }
                items=fieldTextList[i].split(/[ ()]/);
                field.type=items[1];
                if(fieldTextList[i].indexOf("(")!=-1){
                    field.size=items[2];
                }
                if(fieldTextList[i].indexOf("NOT NULL")==-1){
                    field.allowNull=true;
                }else{
                    field.allowNull=false;
                }
                //console.log("field...");
                //console.log(field);
                fieldList.push(field);
            }
            table.fieldList=fieldList;
            return table;
        },
        load:function(){
            let tableList=[];
            for(let i=0;i<this.fileList.length;i++){
                let reader=new FileReader();
                reader.readAsText(this.fileList[i],"UTF-8");
                let mythis=this;
                reader.onload=(evt)=>{
                    let fileString=evt.target.result;
                    let table=mythis.handleFileText(fileString);
                    tableList.push(table);
                    if(tableList.length==mythis.fileList.length){
                        mythis.$emit("getTableList",tableList);
                    }
                    //console.log("table...");
                    //console.log(table);
                }
            }
        },
        addFile:function(event){
            //let file = event.target.files[0];
            //console.log(file);
            this.fileList.push(...event.target.files);
            // let reader = new FileReader();
            // reader.readAsText(file, "UTF-8");
            // let mythis=this;
            // reader.onload = (evt) => {
            //     const fileString = evt.target.result;
            //     //console.log(fileString);
            //     mythis.handleFileText(fileString);
            //     // let dataArr = [];
            //     // for (let index = 1; index < count; index++) {
            //     //     const fileline = fileString.split("\r\n")[index].split(",")[0];
            //     //     console.log(fileline);
            //     //     const filelineNumber = (fileline.split(/\s+/)).map(Number)
            //     //     dataArr.push(filelineNumber)
            //     // }
            // }
        },
        // connectDataBase:function(){
        //     // localStorage.setItem("dataBase",JSON.stringify(this.dataBase));
        //     // request.post("ssmCode/getAllTable",qs.stringify(this.dataBase)).then((res)=>{
        //     //     //console.log(res);
        //     //     this.tableList=res.data;
        //     //     for(let i=0;i<this.tableList.length;i++){
        //     //         this.selectList.push(false);
        //     //     }
        //     // })


        //     // const connect=mysql.createConnection({
        //     //     user:"root",
        //     //     password:"123418abc",
        //     //     host:"localhost",
        //     //     database:"lhhomepage",
        //     //     port:"3306"
        //     // })
        //     // connect.connect((err)=>{
        //     //     console.log(err);
        //     // })
        //     //connect.query("select * from test1");
        // },
        // loadTableList:function(){
        //     let tableList=[];
        //     for(let i=0;i<this.tableList.length;i++){
        //         if(this.selectList[i]){
        //             tableList.push(this.tableList[i]);
        //         }
        //     }
        //     // let data={};
        //     // data.tableNameList=tableList;
        //     // data.url=this.dataBase.url;
        //     // data.user=this.dataBase.user;
        //     // data.password=this.dataBase.password;
            
        //     request.post("ssmCode/loadTableList?url="+encodeURIComponent(this.dataBase.url)+"&user="+this.dataBase.user+"&password="+this.dataBase.password,tableList).then((data)=>{
        //         //console.log(res);
        //         let tableList=[];
        //         for(let tableItem of data){
        //             let table=new Table();
        //             table.clone(tableItem);
        //             tableList.push(table);
        //         }
        //         this.$emit("getTableList",tableList);
        //     })
        // },
        deleteFile:function(index){
            this.fileList.splice(index);
        }
    }
}
</script>

<style lang="less" scoped>
.loadTableList{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    .fileList{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5px;
        align-items: center;
        .item{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px gainsboro solid;
            padding: 5px;
            span{
                padding-right: 10px;
            }
        }
    }
}
</style>